/* eslint-disable no-unused-vars */
import lazySizes from 'lazysizes'
import SplitType from 'split-type'
import gsap from 'gsap'

export default class Home {
	constructor() {
		this.header = document.querySelector('.header')
		this.hamburger = document.querySelector('.hamburger')
		this.container = document.querySelector('.home')
		this.svgLogo = this.container.querySelector('.home-svgLogo .mainTitle')
		this.servicesList = this.container.querySelector('.home-servicesList')
		this.servicesListItems = [...this.container.querySelectorAll('.home-servicesListItem')]
		this.projectsListItem = this.container.querySelector('.home-projectsListItem')
		this.title = this.container.querySelector('.home-title')
		this.splitTypes = this.title.querySelectorAll('span')
		this.text = new SplitType(this.title, { types: 'chars' })
		this.tl = gsap.timeline()

		this.mql = window.matchMedia('(max-width: 1024px)')
	}

	init() {
		window.lenis.stop()
		this.animateIntro()
	}

	animateIntro() {
		this.tl
			.to(this.title, {
				opacity: 1
			})
			.fromTo(
				this.text.chars,
				{
					opacity: 0
				},
				{
					stagger: 0.02,
					opacity: 1
				}
			)
			.to(
				this.servicesList,
				{
					y: 0,
					width: '100%',
					ease: 'expo.out',
					delay: 1,
					opacity: 1
				},
				'-=0.2'
			)
			.fromTo(
				this.servicesListItems,
				{
					opacity: 0,
					y: 10
				},
				{
					opacity: 1,
					y: 0,
					stagger: 0.05,
					duration: 0.3
				},
				'-=0.2'
			)
			.to(
				this.header,
				{
					opacity: 1
				},
				'-=0.3'
			)
			.call(() => {
				window.lenis.start()
			})

		if (this.mql.matches) {
			this.tl
				.fromTo(
					this.svgLogo,
					{
						y: -10
					},
					{
						y: 0,
						opacity: 1
					},
					'-=0.3'
				)
				.fromTo(
					this.hamburger,
					{
						y: -10
					},
					{
						y: 0,
						opacity: 1
					},
					'-=0.3'
				)
		}

		this.tl.fromTo(
			this.projectsListItem,
			{
				opacity: 0,
				y: 50
			},
			{
				opacity: 1,
				duration: 0.5,
				y: 0
			},
			'-=0.4'
		)
	}
}
